:root {
  --light: #B8AE9C;
  --dark: #595241;
  --blue: #ACCFCC;
  --red: #8A0917;
  --bHeight: 4px;
}

/* CSS */
#back-button {
  background-color: #3DD1E7;
  border: 0 solid #E5E7EB;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  padding: .75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 50%;
  height: 30%;
  max-width: 200px;
  max-height: 50px;
  position: absolute;
  z-index: 1;
  top: 60px; 
  left: 30px; 
  cursor: pointer;
  transform: rotate(-2deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  display: none;
  animation: fadeIn 2s;
}

#try-button {
  margin-top: 10px;
  background-color: #3DD1E7;
  border: 0 solid #E5E7EB;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  padding: .75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 50%;
  height: 30%;
  max-width: 200px;
  max-height: 50px;
  z-index: 1;
  cursor: pointer;
  transform: rotate(-2deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: 0 auto;
  display: block;
}

#back-button:focus, #try-button:focus {
  outline: 0;
}

#back-button:after,  #try-button:after{
  content: '';
  position: absolute;
  border: 1px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

#back-button:hover:after, #try-button:hover:after {
  bottom: 2px;
  left: 2px;
}

#close-btn {
  display: inline-block;
  position: absolute;
  margin-top: 10px;
  padding: 1px 10px 5px;
  top: 10px;
  right: 20px;
  background-color: var(--red);
  box-shadow: 0px 5px #49050d;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Source Code Pro', Menlo, Consolas, Monaco, monospace;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  line-height: 1.3;
}

.button--inline {
  padding: .25em .5em;
}

#close-btn:hover,
#close-btn:focus {
  background-color: #cc1c30;
  box-shadow: 0 var(--bHeight) 0 var(--red);
}

#close-btn:active {
  box-shadow: 0 0 0 var(--red);
  transform: translateY(5px);
}


@media (min-width: 768px) {
    #back-button {
    padding: .75rem 3rem;
    font-size: 1.25rem;
  }
}

@keyframes fadeIn {
    0% { opacity: 0;}
    100% { opacity: 1; }
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0;}
    
  }
  

  