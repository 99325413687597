
.loading-fallback {
  position: relative;
  height: 100%;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  .loading-fallback::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  animation: none;
  display: block;
  background-image: url("./paper3.jpg");
  transition: opacity 0.5s ease-in-out;
  }

/* .loading-fallback.show {
  opacity: 1;
}  */

.canvas-container {
  position: relative;
  width: 100%;
  height: 100%;
}


#canvas-child {
  position: relative;
  height: 100%;
  z-index: 0;
  opacity: 1;
  animation: none;
  overflow: hidden;
  overflow-y: auto;
}

.nav-circle {
  opacity: 0;
  display: none;
  animation: none;
}

/* 
.room.fade-in {
  opacity: 0;
  animation: fade-in 1s ease forwards;
}

.room.fade-out {
  opacity: 1;
  animation: fade-out 1s ease forwards;
} */

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}